import React, { useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import FsLightbox from "fslightbox-react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Gallery.css"; // Import custom CSS

function Gallery({ data }) {
  const { OrionsGalleryImages: galleries } = data || {
    OrionsGalleryImages: [],
  };

  const sliderImages = galleries.map(
    (item) => `${process.env.REACT_APP_UPLOAD_URL}${item.imagePath}`
  );

  const [toggler, setToggler] = useState(false);
  const [activeImage, setActiveImage] = useState(0); // Zero-based indexing

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: false,
    centerPadding: "0",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <>
      <section className="row_am gallery_section">
        {/* <div className="container"> */}
        <div className="section_title text-center pt-5 mb-5 mt-5">
          {data?.subTitle && (
            <span
              className="sub_text mb-1"
              data-aos="fade-down"
              data-aos-duration={1500}
            >
              {data?.subTitle}
            </span>
          )}
          {data?.title && (
            <h2 data-aos="zoom-in" data-aos-duration={1500}>
              {data?.title}
            </h2>
          )}
        </div>
        <Slider {...settings} className="diagonal-slider">
          {galleries.map((item, index) => (
            <div
              key={index}
              className={`slide-item ${index === 0 ? "first" : ""} ${
                index === galleries.length - 1 ? "last" : ""
              }`}
            >
              <div
                className="img-wrapper"
                onClick={() => {
                  setToggler(!toggler);
                  setActiveImage(index);
                }}
              >
                <img
                  src={`${process.env.REACT_APP_UPLOAD_URL}${item?.imagePath}`}
                  alt={`Gallery ${index}`}
                  className="img-fluid diagonal-image"
                />
              </div>
            </div>
          ))}
        </Slider>
        {/* </div> */}
      </section>
      <FsLightbox
        toggler={toggler}
        sources={sliderImages}
        sourceIndex={activeImage} // Use sourceIndex instead of slide
      />
    </>
  );
}

export default Gallery;
