import React, { useState } from "react";
import DateLocale from "../Helper/DateLocale";
import BookBtn from "../Helper/BookButton";

import Modal from "./Modal";

function Events({ data: response, header }) {
  const { data } = response || { data: [] };

  const [showModal, setShowModal] = useState(false);

  // const BookBtn = ({ data }) => {
  //   if (!data || !data?.date) return null;

  //   const currentDate = new Date();
  //   const itemDate = new Date(data?.date);

  //   if (itemDate > currentDate) {
  //     return (
  //       <div className="book_now">
  //         <a href={data?.btnUrl} className="btn btn_primary">
  //           {data?.btnText || "RSVP"}
  //         </a>
  //       </div>
  //     );
  //   }
  // };

  return (
    <>
      <section id="events" className="orion_events">
        <div className="container mb-5 pb-5">
          <div className="section_title text-center pt-5 mb-5 mt-5">
            {header?.subTitle && (
              <span
                className="sub_text mb-1"
                data-aos="fade-down"
                data-aos-duration={1500}
              >
                {header?.subTitle}
              </span>
            )}
            {header?.title && (
              <h2 data-aos="zoom-in" data-aos-duration={1500}>
                {header?.title}
              </h2>
            )}
          </div>
          <div className="row no-gap mb-5">
            {data?.length > 0 &&
              data?.map((item, index) => (
                // <div
                //   key={item?.id}
                //   className="col-md-6 position-relative orion_card"
                // >
                //   <img
                //     src={`${process.env.REACT_APP_UPLOAD_URL}${item?.imagePath}`}
                //     alt={`OrionsEvent-${item.id}`}
                //     id={`OrionsEvent-${item.id}`}
                //     className="img-fluid"
                //   />
                // </div>
                <div
                  key={item?.id}
                  className="col-md-6 position-relative orion_card"
                >
                  <img
                    src={`${process.env.REACT_APP_UPLOAD_URL}${item?.imagePath}`}
                    alt={`OrionsEvent-${item.id}`}
                    id={`OrionsEvent-${item.id}`}
                    className="img-fluid"
                  />
                  <div className="overlay">
                    <div className="content">
                      <h3>{item?.title}</h3>
                      <p className="lead">{item?.detail}</p>
                      <p className="date">
                        {item?.date && <DateLocale data={item?.date} />}
                      </p>
                      <BookBtn data={item} />
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="text-center">
            <button
              className="btn btn_primary"
              onClick={() => setShowModal(true)}
            >
              View All
            </button>
          </div>
        </div>
        {showModal && (
          <Modal
            onClose={() => setShowModal(false)}
            apiUrl={"/orions/events?others=true&limit=6&page="}
            title="All Events"
          />
        )}
      </section>
    </>
  );
}

export default Events;
