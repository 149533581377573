import React from "react";

import HeroImg from "../../assets/images/orions/hero-bg.jpg";

function AbtBanner({ data }) {
  const isVideo = /\.(mp4|avi|mkv|webm|mov|wmv|flv|3gp|mpeg|ogg)$/i.test(
    data?.backgroundPath
  );

  return (
    <>
      <section
        className="orion_about"
        style={{
          padding: "100px 0px 100px 0px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        {isVideo ? (
          <video
            className="background-video"
            src={`${process.env.REACT_APP_UPLOAD_URL}${data?.backgroundPath}`}
            autoPlay
            loop
            muted
          />
        ) : (
          <div
            className="background-image"
            style={{
              backgroundImage: `url("${
                data?.backgroundPath
                  ? `${process.env.REACT_APP_UPLOAD_URL}${data?.backgroundPath}`
                  : HeroImg
              }")`,
            }}
          ></div>
        )}

        <div className="container">
          <div className="section_title">
            <div
              className="lead mt-5 mb-5"
              data-aos="fade-up"
              data-aos-duration={1500}
              dangerouslySetInnerHTML={{ __html: data?.content }}
            ></div>
          </div>
        </div>
      </section>

      <style jsx>{`
        .orion_about {
          position: relative;
          z-index: 1;
        }

        .background-video {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: -1;
        }

        .background-image {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-size: cover;
          background-position: center;
          z-index: -1;
        }

        .orion_about .container {
          position: relative;
          z-index: 2;
        }
      `}</style>
    </>
  );
}

export default AbtBanner;
