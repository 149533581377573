import React, { useState } from "react";
import DateLocale from "../Helper/DateLocale";
import Modal from "./Modal";

function Promotions({ data: response, header }) {
  const { data } = response || { data: [] };

  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <section id="promotions" className="orion_events">
        <div className="mb-5 pb-5">
          <div className="section_title text-center pt-5 mb-5 mt-5">
            {header?.subTitle && (
              <span
                className="sub_text mb-1"
                data-aos="fade-down"
                data-aos-duration={1500}
              >
                {header?.subTitle}
              </span>
            )}
            {header?.title && (
              <h2 data-aos="zoom-in" data-aos-duration={1500}>
                {header?.title}
              </h2>
            )}
          </div>
          <div className="row no-gap mb-5">
            {data?.length > 0 &&
              data?.map((item, index) => (
                <div
                  key={item?.id}
                  className="col-md-4 position-relative orion_card"
                >
                  <img
                    src={`${process.env.REACT_APP_UPLOAD_URL}${item?.imagePath}`}
                    alt={`OrionsPromo-${item.id}`}
                    id={`OrionsPromo-${item.id}`}
                    className="img-fluid"
                  />
                  <div className="overlay">
                    <div className="content">
                      <h3>{item?.title}</h3>
                      <p className="lead">{item?.detail}</p>
                      {/* <p className="date">
                        {item?.date && <DateLocale data={item?.date} />}
                      </p> */}
                    </div>
                  </div>
                </div>
              ))}
            {/* <div className="col-md-4 position-relative orion_card">
              <img
                src={PromoOne}
                alt="promotions"
                className="img-fluid w-100"
              />
            </div>
            <div className="col-md-4 position-relative orion_card">
              <img
                src={PromoTwo}
                alt="promotions"
                className="img-fluid w-100"
              />
            </div>
            <div className="col-md-4 position-relative orion_card">
              <img
                src={PromoThree}
                alt="promotions"
                className="img-fluid w-100"
              />
            </div> */}
          </div>
          <div className="text-center">
            <button
              className="btn btn_primary"
              onClick={() => setShowModal(true)}
            >
              View All
            </button>
          </div>
        </div>
        {showModal && (
          <Modal
            onClose={() => setShowModal(false)}
            apiUrl={"/orions/promotions?others=true&limit=6&page="}
            title="All Promotions"
          />
        )}
      </section>
    </>
  );
}

export default Promotions;
